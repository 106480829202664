<div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Edit Customer</h5>
                <button class="btn btn-sm btn-primary close" (click)="closeModal()">X</button>
            </div>
            <div class="modal-body">

                <app-booking-user-edit [passportId]="passportId"  [isModal]="true" (cancelEdit)="closeModal()" (saved)="saved()"></app-booking-user-edit>

            </div>
        </div>
    </div>
</div>

<div class="modal-backdrop fade show modal-slide-show"></div>


<app-booking-navbar [step]="2" [layout]="layout"></app-booking-navbar>

<div class="row h-100 mt-3" *ngIf="creatingCustomer">
    <div class="col-12">
            <app-booking-user-edit [passportId]="selectedUserId" [isModal]="false" [layout]="layout" (cancelEdit)="cancelEdit()"></app-booking-user-edit>
    </div>
</div>

<div class="row h-100 mt-3" *ngIf="!creatingCustomer">
    <div class="col-5">

        <mat-card class="margin-bottom-30">
            <mat-card-content>

                <h2 class="mat-title mb-2">Search for existing customers</h2>
                <div class="form-row align-items-center">
                    <div class="col-10">
                        <div class="form-group">
                            <label>Enter customer name, email or passport number</label>
                            <input class="form-control form-control-sm" type="text" [(ngModel)]="params.searchText"  (keydown.enter)="search()">
                        </div>

                    </div>
                    <div class="col-2">
                        <button class="btn btn-sm btn-primary" style="margin-top: 13px;" type="button" (click)="search()">Search</button>
                    </div>
                </div>





            </mat-card-content>
            </mat-card>

            <mat-card>
                <mat-card-content>

                            <div class="text-center">
                                <h2 class="mat-title mb-2">Unable to find who you're looking for?</h2>
                                <button class="btn btn-sm btn-primary" type="button" (click)="toggleCreating()">Create Customer</button>

                            </div>


                </mat-card-content>
                </mat-card>

    </div>
    <div class="col-7">
            <mat-card class="margin-bottom-30 h-100">
                <mat-card-content>
                    <h2 class="mat-title mb-2">Search results</h2>
                    <div class="mt-4" style="font-size: 18px;" *ngIf="searching">
                        <i class="fa fa-spinner fa-spin"></i> Searching...
                    </div>

                    <table class="mat-elevation-z2 example-full-width data-listing"  *ngIf="!searching">
                        <thead>
                        <tr>
                            <th>Passport</th>
                            <th>Name</th>
                            <th>Email</th>
                            <!-- <th class="text-right">Edit</th>
                            <th class="text-right">Delete</th> -->
            <!--                <th class="text-center">-->
            <!--                    Selected ({{selected.length}})<br />-->
            <!--                    <a class="hover" (click)="selectAll()">All</a> | <a class="hover" (click)="clearSelected()">None</a>-->
            <!--                </th>-->
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let r of data">
                            <td (click)="edit(r)">{{r.cardNumber}}</td>
                            <td (click)="edit(r)"> {{r.firstname}} {{r.surname}}</td>
                            <td (click)="edit(r)">{{r.username}}</td>

                            <td>
                                <button class="btn btn-sm btn-warning" type="button" (click)="select(r)">Select</button>
                                <button class="btn btn-sm btn-warning ml-2" type="button" (click)="edit(r)">Edit</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <app-pagination *ngIf="params.pages > 1" [params]="params" (update)="search()"></app-pagination>

                </mat-card-content>
                </mat-card>


        </div>
</div>


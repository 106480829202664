<div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Making payment of {{paymentData.valueToDisplay | currency: 'GBP'}} for {{paymentData.shopper.name.firstName}} {{paymentData.shopper.name.lastName}}</h5>
                <a class="close hover" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </a>
            </div>
            <div class="modal-body">
                <div class="text-center">

                    <app-adyen *ngIf="paymentData.method === 'card'" [paymentData]="paymentData" (adyenResult)="adyenPaymentSuccess($event)"></app-adyen>
                    <app-adyen-paylink *ngIf="paymentData.method === 'link'" [paymentData]="paymentData" (adyenResult)="adyenPaymentSuccess($event)"></app-adyen-paylink>
                    <app-adyen-stored *ngIf="paymentData.method === 'stored'" [paymentData]="paymentData" (adyenResult)="adyenPaymentSuccess($event)"></app-adyen-stored>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-backdrop fade show modal-slide-show"></div>

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TrybeService } from 'src/app/services/trybe.service';

@Component({
    selector: 'app-trybe-package-select-item',
    templateUrl: './trybe-package-select-item.component.html',
    styleUrls: ['./trybe-package-select-item.component.css']
})
export class TrybePackageSelectItemComponent implements OnInit {
    @Input() dateSelected;
    @Output() itemAdded = new EventEmitter;
    @Output() cancelled = new EventEmitter;

    package = undefined;
    processing = false;
    cancelConfirm = false;
    error = '';

    items = [];
    // guests = [{ name: 'Guest 1' }, { name: 'Guest 2' }];

    offering = undefined;
    basket = undefined;

    constructor(
        private tryBeService: TrybeService,
    ) { }

    ngOnInit(): void {
        this.getPackage();
    }

    close() {
        this.cancelled.emit();
        // this.complete.emit({ action: false });
    }
    closeConfirm() {
        this.cancelConfirm = true;
    }
    back() {
        this.cancelConfirm = false;

    }
    getPackage() {
        this.tryBeService.getPackage('67911f1e7c63a972c40c34e3').subscribe(data => {
            console.log(data.data.data.item_choices)
            this.package = data.data.data;
            this.items = data.data.data.item_choices;
        })
    }

    selectOffering(offering) {
        console.log(offering)
        this.offering = offering;
    }

    continue() {
        this.processing = true;
        // Create the basket
        console.log(`creating the basket`)
        this.tryBeService.basketCreate().subscribe(data => {
            console.log(data.data.data)
            console.log(`Created basketId ${data.data.data.id}`)
            this.basket = data.data.data;

            this.addPackageItem();
        })
        // add the package

        // update the package choice
    }

    addPackageItem() {
        console.log(this.dateSelected)
        const guests = [
            {
                "name": "Guest 1",
                "first_name": null,
                "last_name": null,
                "is_lead_booker": true,
                "membership_number": null,
                "customer_id": null
            },
            {
                "name": "Guest 2",
                "first_name": null,
                "last_name": null,
                "is_lead_booker": false,
                "membership_number": null,
                "customer_id": null
            }
        ]

        this.tryBeService.basketAddPackageItem('67911f1e7c63a972c40c34e3', this.basket.id, this.dateSelected, guests).subscribe(data => {
            console.log(data.data.data);
            this.basket = data.data.data;
            console.log(data.data);
            if (data.data.error) {
                this.error = data.data.message;
                this.basket = undefined;
            } else {
                this.addPackageOption();

            }


        })
    }


    addPackageOption() {
        this.tryBeService.basketAddPackageItemOption(this.basket, this.offering, this.dateSelected).subscribe(data => {
            console.log(data.data);
            if (data.data.error) {
                this.error = data.data.message;
                this.basket = undefined;
            } else {
                this.basket = data.data.data;
                this.processing = false;
                this.itemAdded.emit(this.basket);

            }
        })

    }

    //   addToTrybeBasket(appointment) {
    //     this.tryBeService.basketAddItem(this.basketItem.spa.trybeBasket.id, this.offering, appointment, this.guests[this.guestIdx]).subscribe(data => {
    //         this.basketItem.spa.trybeBasket = data.data.data;
    //         const nextIdx = this.guestIdx + 1;

    //         if (nextIdx < this.guests.length) {
    //             this.guestIdx = nextIdx;
    //             this.getTimeSlots()
    //         } else {

    //             this.gaService.addSpaProduct(this.offering, appointment)
    //             this.confirm()
    //         }

    //     })
    // }

}

<div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 *ngIf="package" class="modal-title">{{package.name}}</h5>
                <button *ngIf="!cancelConfirm" class="btn btn-sm btn-primary close" (click)="closeConfirm()">X</button>
                <button *ngIf="cancelConfirm" class="btn btn-sm btn-primary close" (click)="close()">X</button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="cancelConfirm">
                    <p>Warning - the rate selected can only be booked along with the Best of Pigs treatments</p>
                    <p>Cancelling will not book this room at the rate selected</p>
                    <div class="row">
                        <div class="col-6">
                            <button type="button"  class="btn btn-primary btn-block" (click)="back()">
                                Go back
                            </button>

                        </div>
                        <div class="col-6">
                            <button type="button"  class="btn btn-danger btn-block" (click)="close()">
                                Close
                            </button>

                        </div>
                    </div>

                </ng-container>
                <ng-container *ngIf="!cancelConfirm">
                    <ng-container *ngIf="package">
                        <p style="font-size: 16px;" [innerHTML]="package.description"></p>
                        <div *ngFor="let item of items">
                            <!-- <p>{{item.name}}</p> -->

                            <div *ngIf="offering" class="mb-2">
                                <div *ngIf="error" class="text-danger">{{error}}</div>
                                <ng-container *ngIf="!error">
                                    <button type="button"  class="btn btn-primary btn-block" *ngIf="processing">
                                        Adding to basket
                                        <span class="fa fa-spin fa-spinner"></span>
                                    </button>
                                    <button type="button"  class="btn btn-primary btn-block" *ngIf="!processing" (click)="continue()">
                                        Book and continue
                                    </button>

                                </ng-container>

                            </div>
                            <div *ngFor="let option of item.options">

                                <div class="container trybe-tile">
                                    <div class="row">
                                        <div class="col-4 p-1">
                                            <div *ngIf="option.offering.image" class="image-tile" [ngStyle]="{'background-image': 'url(' + option.offering.image.url + ')'}">

                                            </div>

                                        </div>
                                        <div class="col-8 p-1">
                                            <div class="overview">
                                                <p class="section-subtitle font-light mb-1" style="font-size: 16px;">{{option.offering.name}}</p>
                                                <!-- <p class="section-subtitle font-light mb-1">From {{(offering.price_from / 100) | currency : 'GBP'}}</p> -->
                                                <!-- <p class="mb-1">Lasts {{option.offering.duration}} minutes</p> -->

                                                <!-- <hr /> -->
                                                <div style="font-size: 15px;" [innerHTML]="option.offering.description"></div>
                                                <!-- <p class="">{{offering.description}}</p> -->
                                                <div>
                                                    <ng-container *ngIf="!offering">
                                                        <button type="button" style="width: 185px;" class="btn btn-primary" (click)="selectOffering(option.offering)">Select</button>

                                                    </ng-container>
                                                    <ng-container *ngIf="offering">
                                                        <button *ngIf="offering.id !== option.offering.id" type="button" style="width: 185px;" class="btn btn-primary" (click)="selectOffering(option.offering)">Select</button>
                                                        <button *ngIf="offering.id === option.offering.id" type="button" style="width: 185px;" class="btn btn-primary" (click)="selectOffering(option.offering)">Selected <i class="fa fa-check-circle"></i></button>

                                                    </ng-container>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <!-- <p>{{option.offering.name}}</p>
                                <div [innerHTML]="option.offering.description"></div> -->
                            </div>
                        </div>

                    </ng-container>

                </ng-container>
            </div>
        </div>
    </div>
</div>

<div class="modal-backdrop fade show modal-slide-show"></div>

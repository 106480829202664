import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from 'src/app/store';

@Component({
    selector: 'app-booking-navbar',
    templateUrl: './booking-navbar.component.html',
    styleUrls: ['./booking-navbar.component.css']
})
export class BookingNavbarComponent implements OnInit {
    @Input() step = 1;
    @Input() layout = 'booking';

    $basket = this.store.select<any>('basket');

    constructor(
        private router: Router,
        private store: Store,
    ) { }

    ngOnInit() {
    }

    navTo(v) {
        console.log(v)
        if (v === 1) {
            if (+this.step > +v) {
                this.router.navigate(['/bookings/create/availability'])
            }
        }
        if (v === 2) {
            if (+this.step > +v) {
                this.router.navigate(['/bookings/create/user'])
            }
            if (this.layout === 'adhoc') {
                this.router.navigate(['/bookings/paymentlink'])

            }
        }
        if (v === 3) {
            if (+this.step > +v) {
                this.router.navigate(['/bookings/create/room'])
            }
        }
        if (v === 4) {
            if (+this.step > +v) {
                this.router.navigate(['/bookings/create/payment'])
            }
        }

    }
}

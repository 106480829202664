import { Store } from './../store';
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToolsService } from 'src/app/services/tools.service';
import { AdyenService } from 'src/app/services/adyen.service';
import { HotelService } from '../services/hotel.service';

@Component({
    selector: 'app-payment-link',
    templateUrl: './payment-link.component.html',
    styleUrls: ['./payment-link.component.css']
})
export class PaymentLinkComponent implements OnInit {
    cardErrors = [];
    processing = false;

    hotel;
    hotels = [];

    paymentLinkData = undefined;
    paymentData: any = undefined;
    showPaymentModal = false;

    paymentLinkUrl = '';
    lineItems = [];
    shopper = {
        name: { firstName: '', lastName: '' },
        email: '',
        emailAlt: '',
        firstname: '',
    }

    addingCard = false;

    user;

    constructor(
        private store: Store,
        private router: Router,
        private fb: UntypedFormBuilder,
        private toolsService: ToolsService,
        private adyenService: AdyenService,
        private hotelService: HotelService,
    ) { }

    ngOnInit(): void {

        this.paymentLinkData = this.store.selectForLocal('paymentLinkData');
        console.log(this.paymentLinkData);

        this.getHotels();
        this.buildShopper();
    }


    getHotels() {
        this.hotelService.allLite().subscribe(data => {
            console.log(data);
            this.hotels = data;

        })
    }


    buildShopper() {
        this.shopper.name = { firstName: this.paymentLinkData.user.firstname, lastName: this.paymentLinkData.user.surname }
        this.shopper.email = this.paymentLinkData.user.username;
        this.shopper.firstname = this.paymentLinkData.user.firstname;
    }


    setPaymentMethod(method) {
        const value = Math.round((+this.paymentLinkData.amountToPay * 100) * 100) / 100;
        const valueToDisplay = +this.paymentLinkData.amountToPay;

        console.log(this.paymentLinkData)

        this.paymentData = {
            method,
            value,
            valueToDisplay,
            shopper: this.shopper,
            lineItems: [],
            user: this.paymentLinkData.user,
            hotel: { id: this.paymentLinkData.hotel.id, name: this.paymentLinkData.hotel.name },
            room: { id: 'NONE', name: '' },
            reference: this.paymentLinkData.description,
            isBooking: false,
            result: undefined,
        }
        this.showPaymentModal = true;
    }

    adyenPaymentSuccess(e) {
        console.log(e)
        this.showPaymentModal = false;
    }
    paymentComplete(e) {
        console.log(e)
        this.showPaymentModal = false;
        if (e.action) {
            this.router.navigate(['/bookings/paymentlink/complete'])
        }

    }
}


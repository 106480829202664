

<app-booking-navbar [step]="1"></app-booking-navbar>

<div class="alert alert-danger" *ngIf="(adjustments$ | async).length">
    <h2 *ngFor="let message of (adjustments$ | async)">
        {{message}}
    </h2>
</div>
<!-- <h3 class="text-white">Current availability</h3> -->
<!-- hotel bar -->
<mat-card class="mt-3">
    <mat-card-content>
        <div class="row">
            <div class="col-3">
                <div class="form-group">
                    <label>Check in</label>
                    <input type="search" class="form-control" matInput (dateChange)="updateCheckInDate()" [(ngModel)]="stay.checkInDate" (click)="startpicker.open()" [matDatepicker]="startpicker" placeholder="Check in from">
                    <mat-datepicker #startpicker></mat-datepicker>
                </div>
            </div>

            <div class="col-2">
                <div class="form-group">
                    <label>Nights</label>
                    <select class="form-control" [(ngModel)]="stay.nights" (ngModelChange)="updateStayParams()">
                        <option [ngValue]="1">1</option>
                        <option [ngValue]="2">2</option>
                        <option [ngValue]="3">3</option>
                        <option [ngValue]="4">4</option>
                        <option [ngValue]="5">5</option>
                        <option [ngValue]="6">6</option>
                        <option [ngValue]="7">7</option>
                    </select>
                </div>


            </div>

            <div class="col-2">
                <div class="form-group">
                    <label>Adults</label>
                    <select class="form-control" [(ngModel)]="stay.adults" (ngModelChange)="updateStayParams()">
                        <option [ngValue]="1">1</option>
                        <option [ngValue]="2">2</option>
                        <option [ngValue]="3">3</option>
                        <option [ngValue]="4">4</option>
                        <option [ngValue]="5">5</option>
                        <option [ngValue]="6">6</option>
                        <option [ngValue]="7">7</option>
                    </select>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label>Children</label>
                    <select class="form-control" [(ngModel)]="stay.children" (ngModelChange)="updateStayParams()">
                        <option [ngValue]="0">0</option>
                        <option [ngValue]="1">1</option>
                        <option [ngValue]="2">2</option>
                        <option [ngValue]="3">3</option>
                        <option [ngValue]="4">4</option>
                        <option [ngValue]="5">5</option>
                        <option [ngValue]="6">6</option>
                        <option [ngValue]="7">7</option>
                    </select>
                </div>
            </div>


        </div>

        <div class="navbar-hotels">
            <ng-container *ngFor="let hotel of hotels; let h = index">
                <div
                    class="nav-hotel-holder"
                    [ngClass]="
                    {
                        'active' : hotel.id === hotelID,
                        'radius-left' : !h,
                        'radius-right' : (h + 1) === hotels.length,
                        'margin-left' : h
                    }"
                    (click)="setHotel(hotel)">
                    <!-- <img class="nav-hotel-image" [src]="hotel.logo" /> -->
                    <div class="nav-hotel-name">
                        {{hotel.name}}
                    </div>
                </div>
            </ng-container>
        </div>

    </mat-card-content>
        </mat-card>

<ng-container *ngFor="let hotel of hotels" class="">
    <ng-container *ngIf="hotel.id === hotelID">
        <div class="multi-day-header-hotel-name  d-none d-sm-block" [style.background-color]="'#' + hotel.background_colour">
            <!--<h1>{{hotel.name}}</h1>-->
        </div>

        <mat-card style="padding: 0 16px;">
            <mat-card-content>

        <div class="calendar-holder">
            <table style="width: 100%" class="multi-day-table fs-2">
                <thead>
                    <tr>
                        <th style="background-color: white;"></th>
                        <th colspan="7" style="border: 0px!important; background-color: white; padding: 0;">

                            <div class="multi-day-header">
                                <table style="width: 100%">
                                    <tr>
                                        <td class="text-left" style="width: 33%">
                                            <a class="hover d-block d-sm-none" (click)="back(3)" *ngIf="canGoBackMobile()">
                                                <i class="fa fa-chevron-left"></i> <span class="nav-text">Previous</span></a>
                                            <a class="hover d-none d-sm-block" (click)="back(maxDays)" *ngIf="canGoBack()">
                                                <i class="fa fa-chevron-left"></i> <span class="nav-text">Previous {{maxDays}} days</span></a>
                                        </td>
                                        <td class="text-center" style="width: 33%">
                                            <p class="mb-0">Room prices are shown for each night of stay</p>
                                        </td>
                                        <td class="text-right" style="width: 33%">

                                            <a class="hover d-block d-sm-none" (click)="forward(3)">
                                                <span class="nav-text">Next </span><i class="fa fa-chevron-right"></i></a>
                                            <a class="hover d-none d-sm-block" (click)="forward(maxDays)">
                                                <span class="nav-text">Next {{maxDays}} days</span> <i class="fa fa-chevron-right"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                        </th>
                    </tr>
                    <tr>
                    <th style="background-color: #FFF!important;">
                        <button class="btn btn-warning btn-sm btn-block" type="button" (click)="getAvailability()">
                            Reload calendar
                            <i class="fa fa-spinner fa-spin ml-2" *ngIf="avLoading"></i>
                        </button>
                    </th>
                    <th class="date"
                        [ngClass]="{'hidden-mobile' : di > 2}"
                        *ngFor="let d of dateKeys; let di = index">
                        <!--{{d.startDate}}-->
                        {{d.startDate | date:'EEE dd MMM '}}
                        <ng-container *ngIf="d.nights > 1">
                            <br />
                            {{d.endDate}}
                            <!-- {{d.jEndDate | date:'EEE dd MMM '}} -->
                        </ng-container>
                    </th>
                </tr>
                </thead>
                <tbody *ngIf="(localAvailability$ | async)">
                <tr *ngFor="let room of hotel.rooms">
                    <th>{{room.name}}</th>
                    <ng-container *ngFor="let d of dateKeys; let di = index">
                        <td
                            [ngClass]="{'hidden-mobile' : di > 2 }"
                            *ngIf="(localAvailability$ | async)[room.roomType]"
                            (click)="viewRoom(hotel, room, d.startDay)">

                            <app-room-cost-multiday
                                [stay]="stay"
                                [localAvailability]="(localAvailability$ | async)"
                                [availability]="(localAvailability$ | async)"
                                [availabilitySearch]="availabilitySearch"
                                [dayToCheck]="d.startDay"
                                [multiday]="true"
                                [promotionsView]="false"
                                [roomCost]="0"
                                [passportValidated]="(passportValidated$ | async)"
                                [offers]="room.offers"
                                [hotel]="hotel"
                                [room]="room"></app-room-cost-multiday>
                        </td>
                        <td
                            [ngClass]="{'hidden-mobile' : di > 2 }"
                            *ngIf="!(localAvailability$ | async)[room.roomType]">
                            <div class="av-cell">

                            </div>

                        </td>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
</mat-card-content>
</mat-card>
    </ng-container>

</ng-container>

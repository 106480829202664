import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '../store';

@Component({
    selector: 'app-payment-modal',
    templateUrl: './payment-modal.component.html',
    styleUrls: ['./payment-modal.component.css']
})
export class PaymentModalComponent implements OnInit {
    @Input() paymentData;

    @Output() complete = new EventEmitter();

    constructor(
        private store: Store,
    ) { }

    ngOnInit() {
        console.log(this.paymentData)
        this.store.set('paymentData', this.paymentData)
    }

    adyenPaymentSuccess(e) {
        console.log(e)
        this.confirm(e)
    }

    confirm(paymentResult) {
        this.complete.emit({ action: true, paymentResult });
    }

    close() {
        this.complete.emit({ action: false });
    }
}

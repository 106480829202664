<div class="row">
    <div class="col-12 col-lg-6 offset-lg-3">

        <ng-container *ngIf="paymentData">
            <div class="complete-container">
                <i class="fa fa-check-circle text-success" style="font-size: 40px;"></i>

                <ng-container *ngIf="paymentData.method === 'link'">
                    <p class="section-subtitle" style="font-size: 28px;">Payment link successful</p>
                    <p class="email" style="font-size: 24px;">A payment link email has been sent to the specified email address.</p>
                </ng-container>
                <ng-container *ngIf="paymentData.method !== 'link'">
                    <p class="section-subtitle" style="font-size: 28px;">Payment successful</p>
                    <p class="email" style="font-size: 24px;">Payment was taken successfully</p>
                </ng-container>

                <div class="mb-2 text-center">
                    <a class="btn btn-primary mb-2" (click)="bookAgain()">
                        Return to start
                    </a>

                </div>
            </div>

        </ng-container>

    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../services/api.service';
import { Store } from '../store';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PassportService {

    env = environment;

    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private store: Store,
    ) { }


    create(user) {
        const dataToSend = {
            adminUser: this.store.selectForLocal('user'),
            user
        };
        return this.http.post(this.env.apiV2Path + 'users/admin', dataToSend)
            .pipe(map((data: any) => {
                console.log(data.data)
                return data
            }));
    }

    update(passport) {
        const dataToSend = {
            adminUser: this.store.selectForLocal('user'),
            user: passport,
        };
        return this.http.put(this.env.apiV2Path + 'users/admin', dataToSend)
            .pipe(map((data: any) => {
                console.log(data.data)
                return data
            }));
    }

    delete(passport) {
        const dataToSend = {
            adminUser: this.store.selectForLocal('user'),
            user: passport,
        };
        return this.http.put(this.env.apiV2Path + 'users/admin/delete', dataToSend)
            .pipe(map((data: any) => {
                console.log(data.data)
                return data
            }));
    }


    search(params) {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            params: params,
        };
        return this.http.post(this.env.apiV2Path + 'users/search', dataToSend)
            .pipe(map((data: any) => {
                console.log(data.data)
                return data
            }));
    }

    markAsPrinted(idArray) {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            idArray,
        };
        return this.http.post(this.env.apiV2Path + 'users/markAsPrinted', dataToSend)
            .pipe(map((data: any) => {
                return data
            }));
    }

    sendWalletLink(userId) {
        const dataToSend = {
            userId,
        };
        return this.http.post(this.env.apiV2Path + 'users/sendWalletLink', dataToSend)
            .pipe(map((data: any) => {
                return data
            }));

    }

    sendPurchaseLink(userId) {
        const dataToSend = {
            userId,
        };
        return this.http.post(this.env.apiV2Path + 'users/sendPurchaseLink', dataToSend)
            .pipe(map((data: any) => {
                return data
            }));

    }


    get(id) {
        return this.http.get(this.env.apiV2Path + 'users/' + id)
            .pipe(map((data: any) => {
                console.log(data.data)
                return data
            }));
    }

    makeGuest(id) {
        const dataToSend = {
            id: id
        }
        return this.http.put(this.env.apiV2Path + 'users/makeGuest', dataToSend)
            .pipe(map((data: any) => {
                console.log(data.data)
                return data
            }));
    }

    reprint(id) {
        return this.http.get(this.env.apiV2Path + 'users/' + id + '/reprint')
            .pipe(map((data: any) => {
                console.log(data.data)
                return data
            }));
    }

    confirm(confirmationId) {
        return this.http.get(this.env.apiV2Path + 'users/confirm/' + confirmationId)
            .pipe(map((data: any) => {
                console.log(data.data)
                return data
            }));
    }

    getCompanies() {
        return this.http.get(this.env.apiV2Path + 'companies/all')
            .pipe(map((data: any) => {
                return data;
            }));
    }

    getStats() {
        return this.http.get(this.env.apiV2Path + 'users/stats')
            .pipe(map((data: any) => {
                console.log(data.data)
                return data
            }));
    }

    dupeCheckEmail(email, userId) {
        return this.http.get(this.env.apiV2Path + 'users/email/' + email + '/' + userId)
            .pipe(map((data: any) => {
                console.log(data.data)
                return data
            }));
    }
}

<div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body">

                <h5 class="text-center">Waiting for customer to confirm passport</h5>

                <p class="mt-4 mb-4 text-center">The customer has been sent an email. Once their passport is confirmed you will be directed to the next step</p>

                <div class="mt-4 mb-4 text-center"  *ngIf="!message">
                    <i class="fa fa-spinner fa-spin" style="font-size: 24px; color: green;"></i>
                </div>
                <div class="mt-4 mb-4 text-center"  *ngIf="!message" style="border: 1px solid #CCC; border-radius: 5px; padding: 8px;">
                    <p>If the customer does not confirm their passport you can click here to revert to a non passport user</p>
                    <button class="btn btn-danger btn-sm" type="button" (click)="makeGuest()">Revert to guest</button>
                </div>

                <div class="mt-4 mb-4 alert alert-success text-center" *ngIf="message">
                    <p class="mb-0 " style="color: white;">{{message}} <i class="fa fa-spinner fa-spin" style="color: white;"></i></p>
                </div>

                <div class="text-center" style="margin-top: 10px;" *ngIf="!message">
                    <h5 style="color: red;">Please do not refresh this page</h5>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-backdrop fade show modal-slide-show"></div>

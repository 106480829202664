import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-booking-user-modal',
    templateUrl: './booking-user-modal.component.html',
    styleUrls: ['./booking-user-modal.component.css']
})
export class BookingUserModalComponent implements OnInit {
    @Input() passportId = '';
    @Output() close = new EventEmitter();
    @Output() savedUser = new EventEmitter();

    active = false;

    constructor() { }

    ngOnInit(): void {
    }

    saved() {
        console.log('emitting saveUser')
        this.savedUser.emit({ action: true });
    }

    closeModal() {
        this.close.emit({ action: false });
    }


}

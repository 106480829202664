
<mat-card class="mt-2" *ngIf="layout === 'booking'">
    <div class="d-flex justify-content-between">
        <div class="d-flex w-25">
            <h4 *ngIf="!($basket | async).user">Create Booking</h4>
            <h4 *ngIf="($basket | async).user">{{($basket | async).user.firstname}} {{($basket | async).user.surname}}</h4>
        </div>
        <div class="d-flex justify-content-center w-25">
            <div class="hover d-flex justify-content-start align-items-center" (click)="navTo(1)">
                <div class="count-badge" [ngClass]="{'active' : step === 1}">1</div>
                <span class="ml-2">Date Selection</span>
            </div>
        </div>
        <div class="d-flex justify-content-center w-25">
            <div class="hover d-flex justify-content-start align-items-center" (click)="navTo(2)">
                <div class="count-badge" [ngClass]="{'active' : step === 2}">2</div>
                <span class="ml-2">User Details</span>
            </div>
        </div>
        <div class="d-flex justify-content-center w-25">
            <div class="hover d-flex justify-content-start align-items-center" (click)="navTo(3)">
                <div class="count-badge" [ngClass]="{'active' : step === 3}">3</div>
                <span class="ml-2">Room Overview</span>
            </div>
        </div>
        <div class="d-flex justify-content-center w-25">
            <div class="hover d-flex justify-content-start align-items-center" (click)="navTo(4)">
                <div class="count-badge" [ngClass]="{'active' : step === 4}">4</div>
                <span class="ml-2">Payment</span>
            </div>
        </div>

    </div>

</mat-card>

<mat-card class="mt-2" *ngIf="layout === 'adhoc'">
    <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-center w-25">
            <h4>Create Payment Link</h4>
        </div>

        <div class="d-flex justify-content-center w-25">
            <div class="hover d-flex justify-content-start align-items-center" (click)="navTo(2)">
                <div class="count-badge" [ngClass]="{'active' : step === 2}">1</div>
                <span class="ml-2">User Details</span>
            </div>
        </div>
        <div class="d-flex justify-content-center w-25">
            <div class="hover d-flex justify-content-start align-items-center" (click)="navTo(4)">
                <div class="count-badge" [ngClass]="{'active' : step === 2}">2</div>
                <span class="ml-2">Send Payment Link</span>
            </div>
        </div>

    </div>

</mat-card>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BasketService } from 'src/app/services/basket.service';
import { Store } from 'src/app/store';

@Component({
    selector: 'app-booking-entry',
    templateUrl: './booking-entry.component.html',
    styleUrls: ['./booking-entry.component.css']
})
export class BookingEntryComponent implements OnInit {

    basket = undefined;
    constructor(
        private store: Store,
        private basketService: BasketService,
        private router: Router,
    ) { }

    ngOnInit() {
        console.log('entry')
        this.store.set('bookingParams', undefined);

        const basket = this.basketService.create();

        this.store.set('basket', basket);
        this.basketService.saveBasket(basket)
        this.router.navigate(['/bookings/create/availability'])
    }


}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Store } from '../store';
import { BasketService } from '../services/basket.service';
import { AdyenService } from '../services/adyen.service';

@Component({
    selector: 'app-adyen-stored',
    templateUrl: './adyen-stored.component.html',
    styleUrls: ['./adyen-stored.component.css']
})
export class AdyenStoredComponent implements OnInit {
    @Input() paymentData = undefined;

    @Output() adyenResult = new EventEmitter;

    processing = false;
    processingPayment = false;

    paymentLinkData;

    cards = [];
    cardIdx = 0;
    cardSelected = undefined;
    paymentResult = undefined;
    error = '';

    constructor(
        private adyenService: AdyenService,
        private basketService: BasketService,
        private store: Store,
    ) { }

    ngOnInit(): void {
        console.log(this.paymentData);
        this.getUserCards();
    }

    getUserCards() {
        const d = {
            userId: this.paymentData.user.id,
            hotelId: this.paymentData.hotel.id,
            roomId: this.paymentData.room.id,
            username: this.paymentData.email,
            merchantAccountCode: this.paymentData.merchantAccountCode

        }
        this.adyenService.getUserCards(d).subscribe(data => {
            console.log(data)
            this.cards = data;
            if (this.cards.length) {
                this.selectCard(0)
            }
        })
    }
    selectCard(idx) {
        this.cardSelected = this.cards[idx];
        this.cardIdx = idx;
    }

    makePayment() {
        // this.paymentData.valueToDisplay = '1.00';
        this.error = '';

        this.paymentData.value = Math.round((+this.paymentData.valueToDisplay * 100) * 100) / 100;

        this.paymentData.pspReference = this.cardSelected.recurringDetailReference;

        this.processingPayment = true;
        this.paymentResult = undefined;
        this.adyenService.chargeStoredCard(this.paymentData).subscribe(data => {
            this.processingPayment = false;
            data.metadata = this.paymentData.metadata
            this.paymentResult = data;
            console.log(data)
            if (data.resultCode === 'Authorised') {
                if (this.paymentData.sendBookingLink) {
                    this.createBookingLink()
                }
                this.adyenResult.emit(data)
            } else {
                this.error = 'Sorry - there was a problem taking payment'
            }

        })
    }

    createBookingLink() {
        this.adyenService.createBookingLink(this.paymentData.metadata).subscribe(data => {
            console.log(data)
        })
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '../store';
import { ApiService } from './api.service';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TrybeService {

    baseUrl = 'https://api.try.be/'
    organisationId = '9a8ca4f6-229a-4d1f-8933-3ec3088ac426';

    constructor(
        private apiService: ApiService,
        private store: Store,
        private http: HttpClient,
    ) { }

    basketCreate() {
        const dataToSend = {};
        return this.http.post(`${environment.apiV2Path}trybe/basket`, dataToSend, this.apiService.getHttpOptions()).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    basketGet(basketId) {
        return this.http.get(`${environment.apiV2Path}trybe/basket/${basketId}`, this.apiService.getHttpOptions()).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    basketAddCustomer(basketId, customer) {
        const dataToSend = {
            first_name: customer.firstname,
            last_name: customer.surname,
            email: customer.email
        }

        return this.http.post(`${environment.apiV2Path}trybe/basket/${basketId}/customer`, dataToSend, this.apiService.getHttpOptions()).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    basketSubmit(basketId) {
        const dataToSend = {}

        return this.http.post(`${environment.apiV2Path}trybe/basket/${basketId}/submit`, dataToSend, this.apiService.getHttpOptions()).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    basketAddPackageItem(offeringId, basketId, date, guests) {
        const dataToSend = {
            offering_type: 'package',
            offering_id: offeringId,
            date,
            guests
        }

        return this.http.post(`${environment.apiV2Path}trybe/basket/${basketId}/package/add`, dataToSend, this.apiService.getHttpOptions()).pipe(
            map((data: any) => {
                return data;
            })
        );
    }


    basketAddPackageItemOption(basket, offering, date) {
        const dataToSend = {
            "choice_id": basket.items[0].option_budgets[0].choice_id,
            "option_id": offering.id,
            "time": `${date}T00:00:00+01:00`,
            "item_configuration": {
                "choice_id": basket.items[0].option_budgets[0].choice_id,
                "option_id": offering.id,
                "time": `${date}T00:00:00+01:00`,
            },
            "basketItemId": basket.items[0].id,
            "basket_id": null
        }
        console.log(dataToSend)
        return this.http.post(`${environment.apiV2Path}trybe/basket/${basket.id}/items/${basket.items[0].id}/package-items`, dataToSend, this.apiService.getHttpOptions()).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    basketAddItem(basketId, offering, appointment, guests) {
        const dataToSend = {
            offering_type: offering.type,
            offering_id: offering.id,
            time: appointment.start_time,
            quantity: 1,
            guests: []
        }
        dataToSend.guests.push(guests)

        return this.http.post(`${environment.apiV2Path}trybe/basket/${basketId}/items`, dataToSend, this.apiService.getHttpOptions()).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    basketRemoveItem(basketId, itemId) {
        return this.http.delete(`${environment.apiV2Path}trybe/basket/${basketId}/items/${itemId}`, this.apiService.getHttpOptions()).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getCategories() {
        return this.http.get(`${environment.apiV2Path}trybe/categories`, this.apiService.getHttpOptions()).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getOfferings(categoryId) {
        return this.http.get(`${environment.apiV2Path}trybe/offerings/${categoryId}`, this.apiService.getHttpOptions()).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    getOffering(offeringType, offeringId) {
        return this.http.get(`${environment.apiV2Path}trybe/offering/${offeringType}/${offeringId}`, this.apiService.getHttpOptions()).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    getOfferingCrossSell(offeringType, offeringId) {
        return this.http.get(`${environment.apiV2Path}trybe/offering/${offeringType}/${offeringId}/crosssell`, this.apiService.getHttpOptions()).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    getOfferingUpSell(offeringType, offeringId) {
        return this.http.get(`${environment.apiV2Path}trybe/offering/${offeringType}/${offeringId}/upsell`, this.apiService.getHttpOptions()).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getItemAvailability(offeringType, offeringId, dateFrom, dateTo, quantity) {
        return this.http.get(`${environment.apiV2Path}trybe/offering/availability/${offeringType}/${offeringId}/${dateFrom}/${dateTo}/${quantity}`, this.apiService.getHttpOptions()).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getTimeSlots(offeringType, offeringId, dateFrom) {
        return this.http.get(`${environment.apiV2Path}trybe/offering/${offeringType}/${offeringId}/appointment-slots/${dateFrom}`, this.apiService.getHttpOptions()).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getPackage(packageId) {
        return this.http.get(`${environment.apiV2Path}trybe/packages/${packageId}`, this.apiService.getHttpOptions()).pipe(
            map((data: any) => {
                return data;
            })
        );
    }


    getPackageTimeSlots(offeringType, packageId, choiceId, dateFrom) {
        return this.http.get(`${environment.apiV2Path}trybe/offering/${offeringType}/${packageId}/package-slots/${choiceId}/${dateFrom}`, this.apiService.getHttpOptions()).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

}

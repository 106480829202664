import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PassportService } from 'src/app/passport/passport.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-passport-validation',
    templateUrl: './passport-validation.component.html',
    styleUrls: ['./passport-validation.component.css']
})
export class PassportValidationComponent implements OnInit {

    @Input() passportId = '';

    @Output() confirmed = new EventEmitter();
    @Output() guest = new EventEmitter();

    availabilitySub = undefined;

    active = false;
    polling = true;

    message = '';
    intervalId: any;
    constructor(
        private passportService: PassportService,
    ) { }

    ngOnInit(): void {
        this.start();
    }

    ngOnDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    start() {
        this.intervalId = setInterval(() => {
            this.getUser();
        }, 5000)
    }


    getUser() {
        this.passportService.get(this.passportId).subscribe(data => {
            console.log(data.data)
            if (data.data.confirmed) {
                this.polling = false;
                this.message = 'Passport has been confirmed.... redirecting to next step'
                this.passportConfirmed(data.data)
            }
        })
    }
    makeGuest() {
        this.passportService.makeGuest(this.passportId).subscribe(data => {
            console.log(data.data)
            this.polling = false;
            this.message = 'Customer converted to guest.... redirecting to next step'
            this.continueAsGuest(data.data)
        })
    }


    passportConfirmed(user) {

        setTimeout(() => {
            this.confirmed.emit(user);
        }, 2000);
    }

    continueAsGuest(user) {
        setTimeout(() => {
            this.guest.emit(user);
        }, 2000);
    }

}
